import { useNavigate } from 'react-router';

import { useProfile } from 'api/useProfile';
import { Actions, Subjects } from 'lib/ability/entities/Ability';

import { useOpenNotEnoughPermissionsModal } from './useOpenNotEnoughPermissionsModal';

type UsePermissions = {
  can: (action: Actions, subject: Subjects) => boolean;
  protectedNavigate: (route: string, action: Actions, subject: Subjects) => void;
  useProtectedCall: <K extends Array<unknown>, T = void>(
    call: (...args: K) => Promise<T> | T,
    action: Actions,
    subject: Subjects,
  ) => (((...args: K) => Promise<T>) | (() => void))[];
};

export const usePermissions = (): UsePermissions => {
  const openNotEnoughPermissionsModal = useOpenNotEnoughPermissionsModal();
  const navigate = useNavigate();
  const { ability } = useProfile();

  const can = (action: Actions, subject: Subjects): boolean => ability.can(action, subject);

  const protectedNavigate = (route: string, action: Actions, subject: Subjects): void => {
    if (can(action, subject)) {
      navigate(route);
    } else {
      openNotEnoughPermissionsModal();
    }
  };

  const useProtectedCall = <K extends Array<unknown>, T = void>(
    call: (...args: K) => Promise<T> | T,
    action: Actions,
    subject: Subjects,
  ): (((...args: K) => Promise<T>) | (() => void))[] => {
    const openNotEnoughPermissionsModal = useOpenNotEnoughPermissionsModal();
    return [can(action, subject) ? call : () => openNotEnoughPermissionsModal()];
  };

  return { can, protectedNavigate, useProtectedCall };
};

export const useIsAllowed = (subject: Subjects, action: Actions): boolean => {
  const { can } = usePermissions();
  return can(action, subject);
};
