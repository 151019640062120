import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  ClubProFeatures,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class AvancementChantierProClass extends Tracker<ClubProFeatures> {
  onPlafondEnSavoirPlus(properties: { [AdminEventsProperties.DealUUID]: string }): void {
    this.sendTracking({
      event: TrackingEvents.Clicked,
      subFeature: 'PlafondEnSavoirPlus',
      properties,
    });
  }
}

export const AvancementChantierPro = new AvancementChantierProClass(
  ClubProFeatures.AvancementChantier,
);
