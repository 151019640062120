import React from 'react';

import { Form } from 'react-final-form';

import { FileDropzoneField } from '@travauxlib/shared/src/components/DesignSystem/components/FileDropzone/Field';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ChantierPvWithDocuments, PvType } from '@travauxlib/shared/src/features/Chantiers/types';
import { PvDocumentName } from '@travauxlib/shared/src/features/Chantiers/utils/PvDocumentName';
import { DownloadableFile } from '@travauxlib/shared/src/types';
import {
  AdminEventsProperties,
  DocumentFinChantierProperties,
} from '@travauxlib/shared/src/utils/tracking';

import { useUploadTempS3Files } from 'api/useUploadTempS3Files';
import { DocumentFinChantier } from 'utils/tracking/DocumentFinChantierTracking';

import { useUploadPv } from '../api/useUploadPv';

type OwnProps = {
  pv?: ChantierPvWithDocuments;
  pvType: PvType;
  chantierUuid: string;
  title: string;
  onlyPreview?: boolean;
};

type Props = OwnProps & {
  handleClose: () => void;
};

const UploadDocumentModal: React.FC<Props> = ({
  handleClose,
  pv,
  pvType,
  chantierUuid,
  onlyPreview,
}) => {
  const { uploadFiles } = useUploadTempS3Files();
  const { uploadPv } = useUploadPv();

  return (
    <Form<{ documents: DownloadableFile[] }>
      initialValuesEqual={() => true}
      initialValues={pv}
      onSubmit={async ({ documents }) => {
        await uploadPv({
          chantierUuid,
          documents,
          pvType,
        });
        const trackingData = {
          [DocumentFinChantierProperties.EndOfChantierDocumentType]: pvType,
          [AdminEventsProperties.DealUUID]: chantierUuid,
          [DocumentFinChantierProperties.NumberOfDocumentsUploaded]: documents.length,
        };

        if (pv) {
          DocumentFinChantier.onModified(trackingData);
        } else {
          DocumentFinChantier.onAdded(trackingData);
        }

        handleClose();
      }}
    >
      {({ handleSubmit, submitting, form, values: { documents } }) => (
        <ModalContent
          validateAction={
            onlyPreview
              ? {
                  label: 'Fermer',
                  onClick: handleClose,
                }
              : {
                  label: 'Confirmer',
                  type: 'submit',
                  disabled: submitting,
                  loading: submitting,
                }
          }
          handleSubmit={handleSubmit}
        >
          <FileDropzoneField
            onRemoveFile={f =>
              form.change(
                'documents',
                documents?.filter(d => d !== f),
              )
            }
            onlyPreview={onlyPreview}
            name="documents"
            multiple
            uploadFiles={uploadFiles}
            validate={v =>
              !!v?.length ? undefined : `L'envoi du PV de ${PvDocumentName[pvType]} est obligatoire`
            }
          />
        </ModalContent>
      )}
    </Form>
  );
};

export const useOpenUploadDocumentModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return (props: OwnProps) =>
    openModal(UploadDocumentModal, {
      ...props,
      size: 'md',
      closable: true,
    });
};
