import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  ClubProFeatures,
  DocumentFinChantierProperties,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class DocumentFinChantierClass extends Tracker<ClubProFeatures> {
  onAdded(properties: {
    [DocumentFinChantierProperties.EndOfChantierDocumentType]: string;
    [AdminEventsProperties.DealUUID]: string;
    [DocumentFinChantierProperties.NumberOfDocumentsUploaded]: number;
  }): void {
    this.sendTracking({
      event: TrackingEvents.Added,
      properties,
    });
  }

  onDeleted(properties: {
    [DocumentFinChantierProperties.EndOfChantierDocumentType]: string;
    [AdminEventsProperties.DealUUID]: string;
  }): void {
    this.sendTracking({
      event: TrackingEvents.Deleted,
      properties,
    });
  }

  onModified(properties: {
    [DocumentFinChantierProperties.EndOfChantierDocumentType]: string;
    [AdminEventsProperties.DealUUID]: string;
    [DocumentFinChantierProperties.NumberOfDocumentsUploaded]: number;
  }): void {
    this.sendTracking({
      event: TrackingEvents.Edited,
      properties,
    });
  }
}

export const DocumentFinChantier = new DocumentFinChantierClass(
  ClubProFeatures.DocumentFinChantier,
);
