import React, { useState, useEffect } from 'react';

import { Form } from 'react-final-form';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { AutoSubmitForm } from '@travauxlib/shared/src/components/AutoSubmitForm';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { doesSuiviChantierEtapeExistsFactory } from '@travauxlib/shared/src/features/SuiviChantier/api/doesSuiviChantierEtapeExists';
import { useSuiviInitialValues } from '@travauxlib/shared/src/features/SuiviChantier/hooks/useSuiviInitialValues';
import {
  FormData,
  SuiviChantierComputed,
  SuiviChantierIntervenant,
} from '@travauxlib/shared/src/features/SuiviChantier/types';
import {
  getAvancementForLots,
  parseFormDataToGetLots,
} from '@travauxlib/shared/src/features/SuiviChantier/utils';
import { computeSuiviChantierAmounts } from '@travauxlib/shared/src/features/SuiviChantier/utils/computeSuiviChantierAmounts';
import { computeThreshold } from '@travauxlib/shared/src/features/SuiviChantier/utils/threshold';

import { useCreateSuiviChantier } from './api/useCreateSuiviChantier';
import { useSaveSuiviChantierDraft } from './api/useSaveSuiviChantierDraft';
import { useSuiviChantier } from './api/useSuiviChantier';
import { useSuiviChantierDraft } from './api/useSuiviChantierDraft';
import { LotAvancement } from './components/LotAvancement';
import { MainSuivi } from './components/MainSuivi';
import { SuiviChantierHistory } from './features/ReadOnly/Loadable';

const useSuiviChantierExists = doesSuiviChantierEtapeExistsFactory(
  'pro',
  SuiviChantierIntervenant.Pro,
  APP_CONFIG,
);
const Index: React.FC = () => {
  const [isCreating, setIsCreating] = useState(false);
  const { uuid: chantierUuid } = useParams<{ uuid: string }>();
  const { suiviChantier, isLoading } = useSuiviChantier(chantierUuid!);
  const createSuiviChantier = useCreateSuiviChantier();
  const { suiviChantierDraft } = useSuiviChantierDraft(chantierUuid!);
  const saveSuiviChantierDraft = useSaveSuiviChantierDraft();
  const navigate = useNavigate();

  const {
    allCyclesComplete,
    isLoading: suiviChantierExistsLoading,
    existingCycle,
  } = useSuiviChantierExists(chantierUuid!);

  const initialValues = useSuiviInitialValues({
    devisList: suiviChantier?.devisList,
    withIsModifyingAnotherLigne: true,
    withPreviousCycle: true,
    suiviChantierDraft,
  });

  useEffect(() => {
    if (!suiviChantierExistsLoading && !allCyclesComplete && existingCycle && !isCreating) {
      toast.warning(
        <>
          L'état d'avancement du chantier a bien été pris en compte. La demande de paiement associée
          est en cours de validation.
        </>,
      );
      navigate(`${existingCycle.uuid}/history?pro`);
    }
  }, [suiviChantierExistsLoading, allCyclesComplete, chantierUuid, existingCycle, isCreating]);

  if (isLoading || !chantierUuid || !suiviChantier || suiviChantierExistsLoading) {
    return <Loader />;
  }

  const remiseMapByDevisToken = suiviChantier
    ? suiviChantier.devisList.reduce(
        (acc, devis) => ({
          ...acc,
          [devis.token]: devis.remises,
        }),
        {},
      )
    : [];

  const suiviChantierAmounts = (lots: FormData, currentThreshold?: number): SuiviChantierComputed =>
    computeSuiviChantierAmounts({
      lots: parseFormDataToGetLots(lots),
      remisesMapByDevisToken: remiseMapByDevisToken,
      montantRemise: suiviChantier.montantRemise,
      montantDejaPaye: suiviChantier.montantDejaPaye,
      totalMontantTVA: suiviChantier.totalMontantTVA,
      threshold: currentThreshold,
    });

  return (
    <div className="bg-neutral-100 h-[100dvh]">
      <Form<FormData>
        onSubmit={async values => {
          setIsCreating(true);
          const lots = parseFormDataToGetLots(values);
          const { currentThreshold } = computeThreshold({
            lots,
            receptionChantierThreshold: suiviChantier.receptionChantierThreshold,
            leveeReservesThreshold: suiviChantier.leveeReservesThreshold,
            isReceptionChantierPvValidated: suiviChantier.isReceptionChantierPvValidated,
            isLeveeReservesPvValidated: suiviChantier.isLeveeReservesPvValidated,
            hasReserves: suiviChantier.hasReserves,
            isPro: true,
          });

          await createSuiviChantier({
            chantierUuid,
            lots,
            montantALibererTTC: suiviChantierAmounts(values, currentThreshold).montantALibererTTC,
            globalPourcentageAvancement: getAvancementForLots(lots),
          });
        }}
        initialValues={initialValues}
      >
        {({ handleSubmit, values }) => (
          <form className="flex flex-col grow h-full" onSubmit={handleSubmit}>
            <AutoSubmitForm
              debounce={1500}
              onSubmit={() =>
                saveSuiviChantierDraft({
                  chantierUuid: chantierUuid,
                  formValues: JSON.stringify(values),
                })
              }
            />
            <Outlet />
          </form>
        )}
      </Form>
    </div>
  );
};

export const SuiviChantier = {
  Index,
  MainSuivi,
  LotAvancement,
  SuiviChantierHistory,
};
