import '@fontsource/noto-sans/latin.css';
import 'index.css';

import React from 'react';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import yn from 'yn';

import { HelmetProvider } from '@travauxlib/shared/src/components/Helmet';
import { ScrollToTop } from '@travauxlib/shared/src/components/ScrollToTop';
import { initTracking } from '@travauxlib/shared/src/lib/TrackingCore';
import { ConsentsProvider } from '@travauxlib/shared/src/utils/consents/ConsentsContext';
import { initDayjs } from '@travauxlib/shared/src/utils/time';

import { App } from 'features/App';

initDayjs();
initTracking({
  token: APP_CONFIG.mixpanel.token,
  enabled: APP_CONFIG.mixpanel.enabled,
  environment: ENVIRONMENT,
});

const gtmConf = APP_CONFIG.gtm;
TagManager.initialize({
  gtmId: 'GTM-NVQCHVX',
  auth: gtmConf.auth,
  preview: gtmConf.preview,
});

if (ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: 'https://e36bbb511a54fc91152bc0d25e8ab113@o4506036781449216.ingest.sentry.io/4506099968180224',
    environment: ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const queryClient = new QueryClient();

const withReactQueryDevTool = yn(import.meta.env.VITE_SHOW_REACT_QUERY);

const root = createRoot(document.getElementById('root')!);

root.render(
  <BrowserRouter>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <ConsentsProvider>
            {withReactQueryDevTool && <ReactQueryDevtools />}
            <ScrollToTop />
            <App />
          </ConsentsProvider>
        </React.StrictMode>
      </QueryClientProvider>
    </HelmetProvider>
  </BrowserRouter>,
);
