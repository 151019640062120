import React from 'react';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ChantierPvWithDocuments } from '@travauxlib/shared/src/features/Chantiers/types';
import { PvDocumentName } from '@travauxlib/shared/src/features/Chantiers/utils/PvDocumentName';
import {
  AdminEventsProperties,
  DocumentFinChantierProperties,
} from '@travauxlib/shared/src/utils/tracking';

import { DocumentFinChantier } from 'utils/tracking/DocumentFinChantierTracking';

import { useDeletePv } from '../api/useDeletePv';

type OwnProps = {
  pv: ChantierPvWithDocuments;
  title: string;
};

type Props = OwnProps & {
  handleClose: () => void;
  title: string;
};

const DeletePvModal: React.FC<Props> = ({ handleClose, pv, title }) => {
  const { uuid: pvUuid, chantierUuid, pvType } = pv;
  const { deletePv, isLoading } = useDeletePv();

  return (
    <FeedbackMessages
      isOpen
      variant="warning"
      title={title}
      cancelAction={{
        label: 'Annuler',
        onClick: handleClose,
        disabled: isLoading,
      }}
      validateAction={{
        label: 'Supprimer',
        onClick: async () => {
          await deletePv({ pvUuid, chantierUuid, pvType });
          DocumentFinChantier.onDeleted({
            [DocumentFinChantierProperties.EndOfChantierDocumentType]: pv.pvType,
            [AdminEventsProperties.DealUUID]: chantierUuid,
          });
          handleClose();
        },
        disabled: isLoading,
      }}
      message={
        <div className="text-b2 text-gray-600">
          Attention, si vous supprimez le procès-verbal de {PvDocumentName[pvType]}, vous ne pourrez
          plus accéder à ce document.
          <br />
          Vous devrez renvoyer un nouveau document pour valider la {PvDocumentName[pvType]}.
        </div>
      }
    />
  );
};

export const useOpenDeletePvModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return (props: OwnProps) =>
    openModal(DeletePvModal, {
      ...props,
      size: 'md',
      closable: true,
    });
};
