import React from 'react';

import { subject } from '@casl/ability';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import {
  useCanAccessChartePro,
  useIsArchitecte,
  useIsHemeaEmployee,
  useProCompany,
} from 'api/profileSelectors';
import { RdvSelector } from 'components/RdvSelector';
import { useDealChantier } from 'features/Chantiers/api/useDealChantier';
import { FeedbackQuestions } from 'features/Deals/components/Activities/FeedbackQuestions';
import { useProClient } from 'features/ProClients/api/useProClient';
import { useClubProPaywall } from 'hooks/useClubProPaywall';
import { useIsAllowed } from 'hooks/usePermissions';
import { Deal } from 'types';

import { DealHeader } from './DealHeader';
import { DealActions } from './DealHeader/DealActions';

import { useGetBonCommandeList } from '../api/useGetBonCommandeList';
import { useUpdateDateRDV } from '../api/useUpdateDateRDV';
import { useConfiguration } from '../features/Estimation/api/useConfiguration';
import { useShouldShowFeedbackQuestions } from '../hooks/useShouldShowFeedbackQuestions';
import { DealsRoutes } from '../routes';

const getTabs = (isArchitecte: boolean): Array<{ to: string; label: string }> => [
  {
    to: '../chantier',
    label: 'Chantier',
  },
  {
    to: '../dossier-consultation',
    label: 'Dossier de consultation',
  },
  {
    to: '../devis',
    label: devisOrProposition({ isArchitecte, plural: true, capitalize: true }),
  },
  {
    to: '../factures',
    label: 'Factures',
  },
  {
    to: '../messages',
    label: 'Messages',
  },
  {
    to: '../bon-commande',
    label: 'Bons de commande',
  },
];

type Props = {
  deal: Deal;
  isTabletOrMobile: boolean;
};

export const DealLayout: React.FC<Props> = ({ deal, isTabletOrMobile }) => {
  const navigate = useNavigate();
  const proCompany = useProCompany();
  const shouldShowFeedbackQuestions = useShouldShowFeedbackQuestions(deal);
  const { chantier, isLoading: isChantierLoading } = useDealChantier(deal.uuid!);
  const { configuration, isLoading: configurationLoading } = useConfiguration(deal.uuid!);
  const proClient = useProClient(deal.proClientUuid);
  const isArchitecte = useIsArchitecte();
  const isHemeaEmployee = useIsHemeaEmployee();
  const { updateDateRDV, isLoading } = useUpdateDateRDV();
  const { bonCommandeList } = useGetBonCommandeList(deal.uuid);
  const isMobile = useIsBelowBreakpoint('tablet');
  const canAccessChartePro = useCanAccessChartePro();
  const showBonCommande = bonCommandeList && bonCommandeList.length;
  const showChantier = chantier !== undefined && !!chantier.lemonwayAccountUrl;

  const filteredTabs = getTabs(isArchitecte)
    .filter(tab => showChantier || tab.label !== 'Chantier')
    .filter(tab => configuration?.budgetTravaux || tab.label !== 'Dossier de consultation')
    .filter(tab => showBonCommande || tab.label !== 'Bons de commande');

  const isAllowed = useIsAllowed(subject('Deal', { isHemea: deal.isHemea }), 'manage');

  const shouldDisplayCharte =
    canAccessChartePro && deal.isHemea && !proCompany?.charteStatus && !isHemeaEmployee;

  if (!isAllowed && shouldDisplayCharte) {
    navigate('/charte');
  }

  useClubProPaywall({ isAllowed: isAllowed || shouldDisplayCharte, deal });

  if (!isAllowed) {
    return null;
  }

  if (!proClient || isChantierLoading || configurationLoading) {
    return <Loader />;
  }

  if (shouldShowFeedbackQuestions) {
    return (
      <>
        <DealHeader
          proClient={proClient}
          deal={deal}
          actions={<DealActions deal={deal} />}
          isTabletOrMobile={isTabletOrMobile}
        />
        <FeedbackQuestions deal={deal} isArchitecte={isArchitecte} />
      </>
    );
  }

  const doesRdvHasToBeBooked =
    deal.needsRDV && !deal?.meeting && deal.slotsRendezVous && deal.slotsRendezVous?.length > 0;

  if (doesRdvHasToBeBooked) {
    return (
      <>
        <DealHeader
          proClient={proClient}
          deal={deal}
          actions={<DealActions deal={deal} />}
          isTabletOrMobile={isTabletOrMobile}
        />
        <RdvSelector
          slotsRendezVous={deal.slotsRendezVous!}
          handleAccept={rdvAt => updateDateRDV({ dealUuid: deal.uuid, meetingDate: dayjs(rdvAt) })}
          submitting={isLoading}
        />
      </>
    );
  }

  return (
    <>
      <DealHeader
        proClient={proClient}
        deal={deal}
        actions={<DealActions deal={deal} />}
        isTabletOrMobile={isTabletOrMobile}
      />
      <Tabs items={filteredTabs} fullWidth={!isMobile} />
      <DealsRoutes
        configuration={configuration}
        chantier={showChantier ? chantier : undefined}
        deal={deal}
      />
    </>
  );
};
